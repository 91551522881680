import type { InternalQuotation } from "~/types/quotation";
import { PolicyStatus } from "~/types";
import { EndorsementStatus, type Endorsement } from "~/types/endorsement";
import { InternalProposalStatus } from "~/types/proposal";

export const getPolicyStatusByQuotation = (
  quotation: InternalQuotation
): PolicyStatus => {
  // Se não tiver proposta
  if (!quotation.proposal) {
    return PolicyStatus.PENDING;
  }
  if (quotation.proposal) {
    // Se a proposta foi enviada e aprovada, e ainda não tem apólice
    if (
      quotation.proposal.proposal_status === InternalProposalStatus.APPROVED &&
      !quotation.proposal.endorsement
    ) {
      return PolicyStatus.EMISSION;
    }

    // Se a proposta foi enviada e recusada
    if (quotation.proposal.proposal_status === InternalProposalStatus.REFUSED) {
      return PolicyStatus.REFUSAL;
    }
  }
  return PolicyStatus.PENDING;
};

export const getPolicyStatusByEndorsement = (
  endorsement: Endorsement
): PolicyStatus => {
  switch (endorsement.policy_status) {
    case EndorsementStatus.APPROVED:
      return PolicyStatus.ADEQUATE;
    case EndorsementStatus.REFUSED:
      return PolicyStatus.REFUSAL;
    case EndorsementStatus.UNDER_ANALYSIS:
      return PolicyStatus.ANALYZE;
    case EndorsementStatus.EXPIRED:
      return PolicyStatus.EXPIRED;
    default:
      return PolicyStatus.REFUSAL;
  }
};
